<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" md="4" sm="8">
                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark flat>
                        <v-toolbar-title>Авторизация</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pb-0 mb-0">
                        <v-form @keyup.native.enter="login">
                            <v-text-field
                                v-model="name"
                                label="Логин"
                                prepend-icon="mdi-account"
                                type="text"
                            ></v-text-field>

                            <v-text-field
                                id="password"
                                v-model="password"
                                label="Пароль"
                                prepend-icon="mdi-lock"
                                type="password"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="login">Войти</v-btn>
                    </v-card-actions>
                </v-card>
                <div v-if="error" class="tile">
                    <v-card class="ma-2">
                        <v-toolbar dark flat>
                            <v-toolbar-title>{{ error }}</v-toolbar-title>
                        </v-toolbar>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { AUTH_REQUEST } from "../store/actions/auth";

export default {
    name: "Login",
    data: () => ({
        error: "",
        name: "",
        password: ""
    }),
    methods: {
        login() {
            const { name, password } = this;
            if (name && password) {
                this.$store.dispatch("loading", true);
                this.$store
                    .dispatch(AUTH_REQUEST, {
                        name,
                        password
                    })
                    .then(() => {
                        this.$store.dispatch("loading", false);
                        this.$router.push("/dashboard");
                    })
                    .catch(error => {
                        this.error =
                            error.statusText.message || error.statusText;
                        this.$store.dispatch("loading", false);
                    });
            }
        }
    }
};
</script>
